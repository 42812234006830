import React, { useEffect, useState } from "react";
import { Link, LoadingSpinner } from "components";
import { objectKeysToCamelCase } from "utils";
import "./WordPressPreview.scss";
import { camelCase, upperFirst } from "lodash";
import queryString from "query-string";

interface WordPressPreviewInterface {
  postType?: string;
}

/**
 * Returns a component normally or in a preview mode.
 * The format of import/export of this is slightly different because of its HOC nature.
 *
 * @param postType - param which is passed to the JSON endpoint
 * @returns The component in either preview mode or not
 *
 */

const WordPressPreview =
  ({ postType }: WordPressPreviewInterface) =>
  (ComponentToView: React.ReactElement): any => {
    const Preview = (props) => {
      const [fetchingData, setFetchingData] = useState(false);
      const [jsonData, setJsonData] = useState();
      const [readyToPreview, setReadyToPreview] = useState(false);
      const urlQueryStrings = queryString.parse(typeof window !== "undefined" ? window.location.search : null);
      const { preview_id, preview } = urlQueryStrings;

      // on initial load, get the data from JSON endpoint
      useEffect(() => {
        // don't fetch the json data if it's not in preview mode.
        // There is two variables here because the sometimes the preview button defaults to the WP query strings
        if (!preview_id && !preview) {
          return null;
        }

        setFetchingData(true);
        const postId = props?.data?.post?.wordpressId;

        const jsonEndPoint = `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/${postType}/${postId}?_embed`;
        fetch(jsonEndPoint)
          .then((response) => response.json())
          .then((data) => {
            setFetchingData(false);
            setJsonData(data);
            setReadyToPreview(true);
          })
          .catch((error) => {
            console.error("Preview error:", error);
            // this.setState({ error: 'There is no page by that ID', fetchingData: false});
          });
      }, []);

      /* If no preview param in URL; return the component normally */
      if (!preview_id && !preview) {
        return <ComponentToView {...props} />;
      }

      // if there is a preview param in URL; show the component with the preview data once it has been fetched from the JSON
      if (fetchingData) {
        // show loading state
        return (
          <div className="wordpress-preview__loading-screen">
            <LoadingSpinner />
            <p className="typography--h1">Fetching updated data...</p>
          </div>
        );
      } else {
        // putting the JSON data in a lovely format which is readable in our components
        if (readyToPreview) {
          const previewData = {
            ...props.data,
            post: {
              ...props.data,
              title: jsonData.title ? jsonData.title.rendered : null,
              content: jsonData.content ? jsonData.content.rendered : null,
              author: jsonData._embedded && jsonData._embedded.author ? jsonData._embedded.author[0] : null,
              categories:
                jsonData._embedded && jsonData._embedded.categories && jsonData._embedded.categories.length > 0
                  ? jsonData._embedded.categories[0]
                  : null,
              tags:
                jsonData._embedded && jsonData._embedded.tags && jsonData._embedded.tags.length > 0
                  ? jsonData._embedded.tags
                  : null,
              featuredImage:
                (jsonData._embedded &&
                  jsonData._embedded["wp:featuredmedia"] &&
                  jsonData._embedded["wp:featuredmedia"][0].source_url) ||
                null,
              yoast: {
                metaTitle: "Preview",
              },
              acfLayout: {
                layout: jsonData.acf
                  ? jsonData.acf.layout.map((item) => ({
                      // ...item,
                      fieldGroupName: upperFirst(camelCase(item.acf_fc_layout)),
                      ...objectKeysToCamelCase(item),
                    }))
                  : [],
              },
            },
          };

          return (
            <>
              <ComponentToView {...props} data={previewData} />
              <Link className="wordpress-preview__close-button" to={window.location.href.split("?")[0]}>
                Close Preview
              </Link>
            </>
          );
        }
        return null;
      }
    };

    return Preview;
  };

export default WordPressPreview;
